@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .w-104 {
    width: 26rem;
  }
  .w-ticket {
    width: 22rem;
  }
  .pr-104 {
    padding-right: 26rem;
  }
}

/* 스크롤바 커스텀 */
*::-webkit-scrollbar {
  width: 10px;
  height: 13px;
}
*::-webkit-scrollbar-track {
  /*background: #111827;*/
  /*border-radius: 5px;*/
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(95, 100, 141, 0.49);
  border-radius: 13px;
  /*border: 3px solid #A5B4FC;*/
}